/* fadeIn */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animated.fadeIn {
  animation: fadeIn 1s ease-in-out;
}

/* fadeInUp */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInUp {
  animation: fadeInUp 1s ease-in-out;
}

/* fadeInDown */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInDown {
  animation: fadeInDown 1s ease-in-out;
}

/* fadeInRight */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInRight {
  animation: fadeInRight 1s ease-in-out;
}

/* fadeInLeft */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInLeft {
  animation: fadeInLeft 1s ease-in-out;
}

.animate-delay-2 {
  animation-delay: 2s;
}

@for $i from 1 through 10 {
  .animated.animate-delay-#{$i} {
    animation-delay: #{$i}s !important;
  }
}

@for $i from 1 through 10 {
  .animated.animate-duration-#{$i} {
    animation-duration: #{$i}s !important;
  }
}


@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }

  60% {
    opacity: 1;
    transform: translateX(25px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

.animated.bounceInLeft {
  animation: bounceInLeft 1s ease-in-out;
}

.loader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 120px;
}

.loader div {
  width: 20px;
  height: 20px;
  background-color: $pure-white;
  border-radius: 50%;
  animation: grow 1.5s infinite ease-in-out;
}

.loader div:nth-child(1) {
  animation-delay: 0s;
}

.loader div:nth-child(2) {
  animation-delay: 0.2s;
}

.loader div:nth-child(3) {
  animation-delay: 0.4s;
}

.loader div:nth-child(4) {
  animation-delay: 0.6s;
}

.loader div:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes grow {

  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.6;
  }
}