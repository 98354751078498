/*button*/
$btn-border-radius: 4px;
$btn-border-radius-sm: 4px;
$btn-border-radius-lg: 4px;

//$btn-padding-x: 50px;


/*form*/
//$input-padding-y:  8px;
$input-padding-x: 12px;
$input-focus-border-color: $secondary-500;

$form-floating-padding-y: 9px;
$form-floating-padding-x: 10px;
$form-floating-height: 42px;

$input-border-radius: 2px;
$input-border-radius-sm: 2px;
$input-border-radius-lg: 2px;

$border-radius: $input-border-radius;

$form-select-bg-size: 20px;



/*accordion*/
$accordion-padding-y:                     10px;
$accordion-padding-x:                     0;
$accordion-color:                         $font-color;
$accordion-bg:                            transparent;
$accordion-border-width:                  0;
$accordion-border-color:                  transparent;
$accordion-border-radius:                 0;
$accordion-inner-border-radius:           0;

$accordion-body-padding-y:                $accordion-padding-y !default;
$accordion-body-padding-x:                $accordion-padding-x !default;

$accordion-button-padding-y:              $accordion-padding-y !default;
$accordion-button-padding-x:              $accordion-padding-x !default;
$accordion-button-color:                  $font-color;
$accordion-button-bg:                     transparent;
$accordion-button-active-bg:              transparent;
$accordion-button-active-color:           $font-color;

$accordion-button-focus-border-color:     red !default; // Deprecated in v5.3.3

$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'><path d='M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z' fill='#{$primary-color}'/></svg>") !default;


$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1400px,
  xxl: 1500px
);


$enable-negative-margins: true