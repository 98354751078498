@import '../commons';

.form-control,
.form-select {
  border-radius: $border-radius;
  border-color: $input-border-color;
  font-size: $size-6;
  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $primary-200;
  }
}

textarea.form-control {
  height: 100px !important;
  min-height: auto;
}


.invalidField {
  input,
  input:hover {
    border-color: $danger-color !important;
  }
  color: $danger-color;
  label {
    color: $danger-color !important
  }
}
.invalid-feedback {
  font-size: 0.75em;
}
.valid-feedback {
  font-size: 0.75em;
}

.form-floating{
  @include media-breakpoint-down(lg){
    margin-bottom: calc($gap-half + 4px);/*equals _mat-form mat-form-field*/
  }
  & > label{
    width: calc(100% - 12px);
    white-space: pre-wrap;
  }
  & > .form-control:focus,
  & > .form-control:not(:placeholder-shown),
  & > .form-control-plaintext,
  & > .form-select{
    padding-top: 1.5rem;
    & ~ label{
      font-size: $size-7;
      white-space: nowrap;
    }
  }

  & > .form-control:focus ~ label::after,
  & > .form-control:not(:placeholder-shown) ~ label::after,
  & > .form-control-plaintext ~ label::after,
  & > .form-select ~ label::after {
    background-color: transparent;
  }

  & > .form-control,
  & > .form-select{
    height: $form-height;
  }
}

.form-control:disabled {
  --bs-secondary-bg: #{$form-disable-bg};
  border: 1px solid $gray-300;
  color: $gray-500;
}

.form-row-action{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $gap-half;
  gap: $gap-half;
  @include media-breakpoint-down(sm){
    flex-direction: column;
  }
  .btn{
    padding-inline: 50px;
    @include media-breakpoint-down(sm){
      width: 100%;
    }
  }
}
