.cards-wrap{
  padding-bottom: 30px;
}

.card{
  flex: 1 0 300px;
  &-img{
    border-radius: 0;
  }
  &-tag{

  }
  &-price{
  }
  &-footer{
    background: transparent;
    border: 0;
    padding-top: 0;
    .btn{
      width: 100%;
      display: flex;
      justify-content: center;
      .btn-icon{
        margin-right: 10px;
      }
    }
  }
}

.card-title {
  margin-bottom: 0;
}
