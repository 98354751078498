/*mat switch*/
.mdc-switch {
  $height: 20px;

  &__track {
  --mdc-switch-track-height: #{$height};
  --mdc-switch-track-shape: #{$height};
  }
  &:enabled .mdc-switch__track::after {
   --mdc-switch-selected-track-color: #e0e0e0;
  }

  &__handle {

  }

  &__icons {
    svg {
      display: none;
    }
  }
  &:enabled{
    .mdc-switch__track::before {
      --mdc-switch-unselected-track-color: #{$secondary-100};
    }
    .mdc-switch__shadow{
      --mdc-switch-handle-elevation-shadow: 2px;
    }
  }
  .mdc-switch__handle::before, .mdc-switch__handle::after {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 2px;
  }
  &.mdc-switch--selected:enabled .mdc-switch__handle::after {
    --mdc-switch-selected-handle-color: #ffffff;
  }
  &.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    --mdc-switch-unselected-handle-color: #ffffff;
  }
  &.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after,
  &.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after{
    --mdc-switch-selected-focus-handle-color: #ffffff;
  }
  &.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
   --mdc-switch-unselected-hover-handle-color: #{$secondary-600};
  }
  &.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after {
    --mdc-switch-unselected-focus-handle-color: #{$secondary-600};
  }
}

/*bootstrap switch*/
.form-switch{
  $height: 20px;
  $svg-fill: str-replace(#{$secondary-500}, "#", "%23");
  .form-check-input{
    height: $height;
    background-color: #e0e0e0;
    width: 36px;
    border-color: transparent;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='3' fill='%23616161' /%3e%3c/svg%3e");
    &:checked{
      height: $height;
      border-color: transparent;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='3' fill='#{$svg-fill}' /%3e%3c/svg%3e")
    }
    &:focus{
      box-shadow: none;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='3' fill='#{$svg-fill}' /%3e%3c/svg%3e")
    }
  }

}
