html,
body {
  height: 100%;
  color: $font-color;
}

body {
  margin: 0;
}

a,
a:not([href]):not([class]),
.link-primary,
.btn-link {
  --bs-primary-rgb: #{$font-color};
  color: $font-color;
  text-underline-offset: 5px;
  cursor: pointer;

  &:hover,
  &:focus {
    color: $secondary-color;
  }
}

::-webkit-scrollbar {
  $size: 8px;
  width: $size;
  height: $size;

  &-track {
    background: #f1f1f1;
  }

  &-thumb {
    background: $secondary-400;
    border-radius: 10px;
  }

  &-thumb:hover {
    background: $secondary-500;
  }
}

hr {
  border-color: $gray-500;
}

b,
strong {
  font-weight: bold;
}

div.offcanvas-backdrop.fade.show {
  background-color: transparent !important;
  backdrop-filter: blur(4px) !important;
  top: $header-primary-height !important;
  opacity: 1 !important;
}

.d-block.modal.fade.show {
  backdrop-filter: blur(4px) !important;
}

.mat-icon-fill {
  font-variation-settings:
    'FILL' 1
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.arc-rotate-45 {
  transform: rotate(-45deg);
}

.arc-rotate-90 {
  transform: rotate(-90deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.cursor-pointer {
  cursor: pointer;
}

.w-max-content {
  max-width: max-content !important;
}

.h-max-content {
  height: max-content !important;
}


.rolling-info {
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  transition: all 0.3s ease-out;
  white-space: nowrap;
  border: none;
  padding-inline: 2px;
  $height: 28px;
  height: $height;
  border-radius: $height;
  background: $secondary-500;
  color: $secondary-contrast-500;
  display: flex;
  align-items: center;
  justify-content: center;

  .rolling-info-icon {
    width: $height;
    height: $height;
    border-radius: $height;
    display: grid;
    place-content: center;
  }

  .rolling-info__text {
    display: none;
    @include h8-semibold;
    padding-inline: 10px;
  }

  &.active {
    width: auto;

    .rolling-info__text {
      display: block;
    }
  }
}


/*text*/
.text-secondary-800 {
  color: $secondary-800;
}



.faq-ol {
  margin: 0 0 10px;
  padding: 0;
  list-style-type: none;

  li {
    counter-increment: step-counter;
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
    align-items: center;

    &:before {
      content: counter(step-counter);
      margin-right: 5px;
      font-size: 80%;
      $height: 30px;
      height: $height;
      width: $height;
      display: inline-grid;
      place-content: center;
      background-color: $danger-500;
      color: white;
      font-weight: bold;
      border-radius: 30px;
    }
  }
}