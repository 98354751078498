$border-color: $gray-200;
$height: $form-height;
$place-holder-text-color: $gray-400;


@include media-breakpoint-down(md){
   mat-form-field{
    margin-bottom: calc($gap-half + 4px); /*equal with _form form-floating*/
  }
}

.mat-mdc-form-field.mat-form-field-appearance-fill,
.mat-mdc-form-field.normalized-mat-control {
  /* Disabled */
  &.mat-form-field-disabled {
    opacity: 0.7 !important;
  }

  /* Default style tweaks and enhancements */
  .mat-mdc-text-field-wrapper {
    padding: 0 10px;
    border-width: 1px;
    border-style: solid;
    border-color: $border-color;
    background: #ffffff;
    border-radius: $form-border-radius;
    height: $height;

    /* Adjust the top spacing and overflow when mat-label present */
    &:not(.mdc-text-field--no-label) {
      overflow: visible;
    }


    .mat-mdc-form-field-focus-overlay {
      background: $gray-500;
      border-radius: $form-border-radius;
    }

    .mdc-line-ripple {

      &:before,
      &:after {
        display: none;
      }
    }
  }

  .mat-mdc-form-field-infix {
    --mat-form-field-container-height: #{$form-height};
    --mat-form-field-filled-with-label-container-padding-top: #{calc($form-height/2 - 4px)};
    --mat-form-field-filled-with-label-container-padding-bottom: 4px;
  }


  &.mat-mdc-form-field-type-mat-select,
  &.mat-mdc-form-field-has-icon-suffix /*Calendar*/
  {
    .mat-mdc-text-field-wrapper {
      height: $height;
    }
    .mat-mdc-form-field-infix {
      --mat-form-field-container-height: #{$form-height};
      //--mat-form-field-container-height: #{calc($form-height - 6px)};
      --mat-form-field-filled-with-label-container-padding-top: #{calc($form-height/2 - 4px)};
      --mat-form-field-filled-with-label-container-padding-bottom: 0px;
    }
  }



  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-90%) scale(0.75);
    white-space: nowrap !important;
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none
  }

}

.mat-mdc-form-field-error {
  @include h8-regular;
}

// Customizations for cart preview buttons
.rounded-selector.mat-mdc-form-field.mat-form-field-appearance-fill {
  width: 100%;
}

//this is used on product card
app-cart-preview .mat-mdc-form-field.mat-form-field-appearance-fill,
.rounded-selector.mat-mdc-form-field.mat-form-field-appearance-fill {
  .mat-mdc-text-field-wrapper{
    height: 28px;
    border-radius: 2px;
    font-size: $size-6;

    .mat-mdc-form-field-flex {
      align-items: center;
      .mat-mdc-floating-label {
        top: 13px;
        &.mdc-floating-label--float-above {
          display: none;
        }
      }
    }
  }
  input {
    padding-top: 3px;
    font-size: $size-8;
  }
  .mat-mdc-form-field-infix {
    padding-top: 0px;
    padding-bottom: 0;
    min-height: 0;
  }
}

.mdc-text-field .mdc-floating-label{
  white-space: pre-wrap;
  --mat-form-field-filled-label-display: inline-flex;
  mat-label{
    line-height: 1.2;
  }
}

.cdk-overlay-pane {
  min-width: 70px;
}

mat-option.mat-mdc-option {
  min-height: 27px;
  font-size: .75em;
}

.mat-pseudo-checkbox {
  margin-left: 15px;
}

.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  width: 12px;
  height: 4px;
}


.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: #{calc($form-height - 18px)};
  padding: 0;
}


.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple{
  border-radius: 5px !important;
}
.mdc-checkbox:hover{
  .mdc-checkbox__ripple{

  }
}

.mdc-switch.mdc-switch--selected .mdc-switch__icon{
  --mdc-switch-selected-icon-size: 13px;
}
.mdc-switch.mdc-switch--unselected .mdc-switch__icon{
    --mdc-switch-unselected-icon-size: 13px;
}



.custom-box,
.product-card-addon{
  $action-height: 30px;
  .mat-mdc-form-field{
    width: 100%;
  }
  .mdc-text-field--no-label{
    &.mat-mdc-text-field-wrapper{
      height: $action-height !important;
    }
    .mat-mdc-form-field-infix{
      width: calc(100% - 50px);
      height: $action-height;
    }
    .mat-mdc-form-field-infix{
      padding-top: 3px;
    }
  }
}


.mat-mdc-select-value{
  font-size: $size-8;
}
