$sidebar-width: 320px;
$breadcrumb-height: 52px;
$padding-inline: 24px;
$padding-block: 16px;
$global-message-height: 40px;

:host-context .container {
  @include media-breakpoint-down(sm) {
    padding-inline: 30px;
  }
}

.layout {

  //height: calc(100vh - $header-height); this will make sticky side menu
  &.has-sidebar--left {
    display: grid;
    grid-template-columns: $sidebar-width 1fr;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
    }

    .content {
      width: calc(100vw - $sidebar-width - 10px);

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .sidebar {
    width: $sidebar-width;
    position: sticky;
    top: 0;
    background: $secondary-50;
    padding: 40px;
    overflow-y: auto;

    @include media-breakpoint-down(md) {
      position: relative;
      width: 100%;
      z-index: 99;
    }
  }

  .content {
    min-height: calc(100vh - $header-height - 260px);
    //overflow-y: auto;
    display: flex;
    flex-direction: column;

    &:hover::-webkit-scrollbar {
      &-thumb {
        background: $primary-500;
      }

      &-thumb:hover {
        background: $primary-800;
      }
    }

    &__main {
      padding: $padding-block $padding-inline;
    }
  }
}

.main-content {
  margin-inline: calc($gap - 12px);
  padding-block: calc($gap - 12px);
}

.main-container__full {
  /*used in product detail*/
  padding-inline: $gap;
  padding-block: $gap;
}

/*header*/
.show-on-shop {
  display: none;
}

/*Main Container*/
.main-container {
  height: 100vh;
  overflow-y: auto;
}

.main-container-global-message {
  height: calc(100vh - $global-message-height);
  overflow-y: auto;
}


/* Mobile main container */
.mobile-main-container {
  height: calc(100vh - $mobile-footer-nav-height);
  overflow-y: auto;
}

.header-showing {
  &.main-container {
    height: calc(100vh - $header-height);
    overflow-y: auto;

    @include media-breakpoint-down(md) {
      height: calc(100svh - $header-height-mobile - $mobile-footer-nav-height);

      &.hidden-mobile-footer {
        height: calc(100vh - $header-height-mobile);
      }
    }
  }
}

.header-showing-global-message {
  &.main-container {
    height: calc(100vh - $header-height - $global-message-height);
    overflow-y: auto;

    @include media-breakpoint-down(md) {
      height: calc(100svh - $header-height-mobile - $mobile-footer-nav-height);

      &.hidden-mobile-footer {
        height: calc(100vh - $header-height-mobile);
      }
    }
  }
}

.header-showing-hidden-sub-nav {
  &.main-container {
    height: calc(100vh - ($header-height - $sub-nav-height));
    overflow-y: auto;

    @include media-breakpoint-down(lg) {
      height: calc(100svh - ($header-height-mobile - $sub-nav-height-mobile) - $mobile-footer-nav-height);

      &.hidden-mobile-footer {
        height: calc(100vh - ($header-height-mobile - $sub-nav-height-mobile));
      }
    }
  }
}

.header-showing-hidden-sub-nav-global-message {
  &.main-container {
    height: calc(100vh - ($header-height - $sub-nav-height - $global-message-height));
    overflow-y: auto;

    @include media-breakpoint-down(lg) {
      height: calc(100svh - ($header-height-mobile - $sub-nav-height-mobile) - $mobile-footer-nav-height - $global-message-height);

      &.hidden-mobile-footer {
        height: calc(100vh - ($header-height-mobile - $sub-nav-height-mobile) - $global-message-height);
      }
    }
  }
}

/*1. Layout Static*/
.layout-static {
  app-mobile-footer-menu {
    display: none;
  }

  .header-showing {
    &.main-container {
      @include media-breakpoint-down(lg) {
        //height: calc(100vh - $header-height-mobile - 118px); //Its confusing why extra 18px need to be subtracted for ios device
        height: calc(100svh - $header-height-mobile);
      }
    }
  }

  .header-showing-hidden-sub-nav {
    &.main-container {
      height: calc(100vh - ($header-height - $sub-nav-height));
      overflow-y: auto;

      @include media-breakpoint-down(lg) {
        height: calc(100svh - ($header-height-mobile - $sub-nav-height-mobile));
      }
    }
  }

  mat-form-field,
  app-search-bar {
    width: 100% !important;
  }
}

/*2. Layout login*/
.layout-login {
  app-mobile-footer-menu {
    display: none;
  }

  .main-container {
    height: 100svh;

    @include media-breakpoint-down(lg) {
      height: 100svh;
    }
  }
}

.layout-login-global-message {
  app-mobile-footer-menu {
    display: none;
  }

  .main-container {
    height: calc(100svh - $global-message-height) !important;

    @include media-breakpoint-down(lg) {
      height: calc(100svh - $global-message-height) !important;
    }
  }
}

/*3. Layout signup*/
.layout-signup {
  app-mobile-footer-menu {
    display: none;
  }

  .main-container {
    height: 100svh;

    @include media-breakpoint-down(lg) {
      height: 100svh;
    }
  }
}

.layout-signup-global-message {
  app-mobile-footer-menu {
    display: none;
  }

  .main-container {
    height: calc(100svh - $global-message-height) !important;

    @include media-breakpoint-down(lg) {
      height: calc(100svh - $global-message-height) !important;
    }
  }
}