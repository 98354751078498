.accordion {
  .accordion-item {
    border-bottom: 1px solid $form-disable-bg;

    .accordion-header {
      @include h4-bold;

      .accordion-button {
        padding-left: 30px;
        font-weight: 500;

        &:after {
          width: 20px;
          position: absolute;
          left: 0;
          top: 10px;
        }
      }
    }
  }
}

.accordion-body {
  padding-block: 10px 30px;
  //--bs-accordion-body-padding-y: 10px 30px;
  //--bs-accordion-body-padding-x;
}

.accordion-button:focus {
  box-shadow: none;
}