@import "themes/_dimensions.scss";
@import "themes/_colors";
@import "themes/_material-colors";
@import "themes/_typography.scss";
@import "themes/_bootstrap.scss";
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints';

@import "responsive/_responsive.scss";

@import 'components/_delivery-info-card.scss';
@import 'components/closed-market-banner.scss';
@import 'themes/utils';

.text-red {
  color: red !important;
}