.btn-wrap{
  display: flex;
  flex-direction: row;
  margin-block: 24px;
  gap: 24px;
  &.is-center{
    justify-content: center;
  }
  @include media-breakpoint-down(sm){
    flex-direction: column;
  }
}

.btn{
  --bs-btn-font-size: #{$size-6};
  --bs-btn-padding-x: 12px;
  --bs-btn-padding-y: 8px;
  line-height: 20px;
  font-weight: 500;
  &.btn-sm{
    --bs-btn-font-size: #{$size-7};
  }
  &.btn-lg{
    --bs-btn-padding-y: .8rem;
    --bs-btn-padding-x: 1.5rem;
    --bs-btn-font-size: #{$size-5};
    --bs-btn-border-radius: 5px;
  }
}

.btn-primary,
.btn-secondary{
  --bs-btn-bg: #{$secondary-500};
  --bs-btn-border-color: #{$secondary-500};
  --bs-btn-color: #{$secondary-contrast-500};
  &:hover {
    --bs-btn-hover-color: #{$secondary-contrast-500};
    --bs-btn-hover-bg: #{$secondary-600};
    --bs-btn-hover-border-color: #{$secondary-600};
  }
}


.btn-outline-secondary,
.btn-outline-primary {
  border: 1px solid $secondary-500;
  background: $pure-white;
  color: $secondary-500;
  &:hover {
    background: $secondary-50;
    color: $secondary-800;
  }
}
