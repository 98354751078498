.nav-tabs {
  border-bottom: 0;
  .nav-link {
    color: $gray-600;
    border-width: 0;
    border-bottom: 2px solid transparent;
    padding-inline: $gap;
    @include h5-regular;
    font-weight: 300;
    &.active {
      color: $secondary-color;
      border: 0;
      border-bottom: 2px solid $secondary-color;
      font-weight: $weight-semibold;
    }
    &:hover,
    &:focus{
      outline: none;
    }
    &:not(.active):hover,
    &:not(.active):focus{
      border-bottom: 2px solid transparent;
      background: $secondary-50;
    }
  }
}
.tab-content{
  padding-block: $gap-half;
}


/*mat-tab*/
.mdc-tab__text-label {
  letter-spacing: normal;
  @include h5-regular;
  .mdc-tab--active &{
    font-weight: $weight-semibold;
  }
}
