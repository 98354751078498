.order-info {
    $gap: 24px;
    width: calc(100% - $gap);
    display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: $gap;
  
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  
    &__item {
      background: $primary-100;
      border-radius: 8px;
      padding: 12px;
      display: grid;
      grid-template-columns: 20px 1fr;
      gap: 12px;
      align-items: center;
      margin-bottom: calc($gap/2);
  
      &.has-action {
        grid-template-columns: 20px 1fr 50px;
      }
  
      &.is-not-flex {
        display: block;
      }
  
      &-title {
        font-weight: $weight-semibold;
        margin-bottom: 2px;
      }
  
      &-text {
        margin-bottom: 0;
      }

      &-text__semibold {
        font-weight: $weight-semibold;
      }
  
      &.is-outline {
        background: none;
        border: 1px solid $secondary-200;
  
        .order-info__item-value {
          @include h7-regular;
  
          .order-info__item-title {
            font-weight: bold;
            margin-bottom: 0;
          }
        }
      }
  
      &.is-default {
        background: $tertiary-100;
        border: 0;
        min-height: calc(100% - 26px);
      }
    }
  }