@mixin small {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: ($tablet + 1)) and (max-width: $desktop) {
    @content;
  }
}

@mixin large {
  @media only screen and (min-width: ($desktop + 1)) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == small) {
      @include small {
        @content;
      }
    } @else if ($key == medium) {
      @include medium {
        @content;
      }
    } @else if ($key == large) {
      @include large {
        @content;
      }
    }
  }
}

