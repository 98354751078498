.mat-horizontal-stepper-header {
  $step-icon-size: 40px;
  padding: 0;
  .mat-step-icon {
    height: $step-icon-size;
    width: $step-icon-size;
    --mat-stepper-header-icon-foreground-color: #{$secondary-100};
    --mat-stepper-header-icon-background-color: transparent;
    --mat-stepper-header-done-state-icon-background-color: #{$secondary-color};
    border: 1px solid $secondary-100;

    &-selected {
      border: 1px solid $secondary-50;
      --mat-stepper-header-selected-state-icon-background-color: #{$secondary-color};
      //  --mat-stepper-header-selected-state-icon-foreground-color: #{$secondary-800};
    }
  }

  .mat-step-label {
    color: $secondary-100;
    --mat-stepper-header-selected-state-label-text-color: #{$black-color};
    @include h4-semibold;
    @media screen and (max-width: $fullhd) {
      @include h6-semibold;
    }
    &-selected{
      --mat-stepper-header-selected-state-label-text-color: #{$secondary-color};
      --mat-stepper-header-selected-state-label-text-size: #{$size-4};
      --mat-stepper-header-selected-state-label-text-weight: #{$weight-semibold};
      @media screen and (max-width: $fullhd) {
        --mat-stepper-header-selected-state-label-text-size: #{$size-6};
      }
    }
  }
}

.mat-icon {
  color: $secondary-500;
}

.mat-step-icon-state-done {
  background-color: transparent!important;
  border: none!important;
}

.mat-horizontal-stepper-wrapper .mat-horizontal-content-container {
  padding: 0;
}


.mat-stepper-horizontal-line {
  --mat-stepper-line-color: transparent;
}

.mat-step-header {

  &:hover:not([aria-disabled]),
  &:hover[aria-disabled=false] {
    --mat-stepper-header-hover-state-layer-color: transparent;
    --mat-stepper-header-hover-state-layer-shape: 10px;

    .mat-step-icon {
      background: $secondary-200;
      border-color: $secondary-200;
    }
  }

  &.cdk-keyboard-focused,
  &.cdk-program-focused {
    --mat-stepper-header-focus-state-layer-color: transparent;
    --mat-stepper-header-focus-state-layer-color: transparent;
    --mat-stepper-header-focus-state-layer-shape: 10px;

    .mat-step-icon {
      background: $secondary-200;
      border-color: $secondary-200;
    }
  }
}


.mat-step-label-active {
  color: #FFF;
}

.mat-step-icon-state-done {
  --mat-stepper-header-done-state-icon-background-color: transparent;
  --mat-stepper-header-done-state-icon-foreground-color: #{$success-500};
  &.mat-step-icon .mat-icon{
    $size: 20px;
    font-size: $size;
    height: $size;
    width: $size;
  }
}

.mat-step-text-label {
  color: #{$secondary-500};
  @include h6-regular();
}

@include media-breakpoint-down(lg) {

  .mat-horizontal-stepper-header-container {
    position: relative;
  }

  .mat-step-header {
    overflow: visible !important;
    position: initial !important;

    .mat-step-label {
      position: absolute;
      top: 55px;
      left: 0;
      width: 100%;
      text-align: center;
      display: none;

      &-selected {
        display: block;
      }
    }
  }

  .mat-step-text-label {
    margin-top: 12px;
  }
}
