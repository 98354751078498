$lights: (50: 9%, 100: 7%, 200: 5%, 300: 3%, 400: 1%);
$darks: (600: 1%, 700: 3%, 800: 5%, 900: 7%);

@function tint($color, $percentage) {
    @return mix(#FFF, $color, $percentage);
}
@function shade($color, $percentage) {
    @return mix(#000, $color, $percentage);
}


$primary-color: #AFC271;
$secondary-color: #007893;
$tertiary-color: #F2EBD1;

$success-color: #04C07A;
$danger-color: #F44C75;
$info-color: #08C1FC;
$warning-color: #FF9500;

$base-black: #131b1c;
$base-white: #F6F6F6;
$gray-color: #A5A5A5;

$pure-white: #ffffff;
$black-color:#35424C;
$white-color: #ffffff;

/*gray color*/
$gray-palette: (
  50:  tint($gray-color, 10 * map-get($lights, 50)),
  100: tint($gray-color, 10 * map-get($lights, 100)),
  200: tint($gray-color, 10 * map-get($lights, 200)),
  300: tint($gray-color, 10 * map-get($lights, 300)),
  400: tint($gray-color, 10 * map-get($lights, 400)),
  500: $gray-color,
  600: shade($gray-color, 10 * map-get($darks, 600)),
  700: shade($gray-color, 10 * map-get($darks, 700)),
  800: shade($gray-color, 10 * map-get($darks, 800)),
  900: shade($gray-color, 10 * map-get($darks, 900)),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$gray-50: map-get($gray-palette, 50);
$gray-100: map-get($gray-palette, 100);
$gray-200: map-get($gray-palette, 200);
$gray-300: map-get($gray-palette, 300);
$gray-400: map-get($gray-palette, 400);
$gray-500: $gray-color;
$gray-600: map-get($gray-palette, 600);
$gray-700: map-get($gray-palette, 700);
$gray-800: map-get($gray-palette, 800);
$gray-900: map-get($gray-palette, 900);

$gray-contrast-500: map-get($gray-palette, contrast, 500);

/*primary*/
$primary-palette: (
  50:  tint($primary-color, 10 * map-get($lights, 50)),
  100: tint($primary-color, 10 * map-get($lights, 100)),
  200: tint($primary-color, 10 * map-get($lights, 200)),
  300: tint($primary-color, 10 * map-get($lights, 300)),
  400: tint($primary-color, 10 * map-get($lights, 400)),
  500: $primary-color,
  600: shade($primary-color, 10 * map-get($darks, 600)),
  700: shade($primary-color, 10 * map-get($darks, 700)),
  800: shade($primary-color, 10 * map-get($darks, 800)),
  900: shade($primary-color, 10 * map-get($darks, 900)),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$primary-50: map-get($primary-palette, 50);
$primary-100: map-get($primary-palette, 100);
$primary-200: map-get($primary-palette, 200);
$primary-300: map-get($primary-palette, 300);
$primary-400: map-get($primary-palette, 400);
$primary-500: $primary-color;
$primary-600: map-get($primary-palette, 600);
$primary-700: map-get($primary-palette, 700);
$primary-800: map-get($primary-palette, 800);
$primary-900: map-get($primary-palette, 900);

$primary-contrast-500: map-get($primary-palette, contrast, 500);


/*secondary*/
$secondary-palette: (
  50:  tint($secondary-color, 10 * map-get($lights, 50)),
  100: tint($secondary-color, 10 * map-get($lights, 100)),
  200: tint($secondary-color, 10 * map-get($lights, 200)),
  300: tint($secondary-color, 10 * map-get($lights, 300)),
  400: tint($secondary-color, 10 * map-get($lights, 400)),
  500: $secondary-color,
  600: shade($secondary-color, 10 * map-get($darks, 600)),
  700: shade($secondary-color, 10 * map-get($darks, 700)),
  800: shade($secondary-color, 10 * map-get($darks, 800)),
  900: shade($secondary-color, 10 * map-get($darks, 900)),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$secondary-50: map-get($secondary-palette, 50);
$secondary-100: map-get($secondary-palette, 100);
$secondary-200: map-get($secondary-palette, 200);
$secondary-300: map-get($secondary-palette, 300);
$secondary-400: map-get($secondary-palette, 400);
$secondary-500: $secondary-color;
$secondary-600: map-get($secondary-palette, 600);
$secondary-700: map-get($secondary-palette, 700);
$secondary-800: map-get($secondary-palette, 800);
$secondary-900: map-get($secondary-palette, 900);

$secondary-contrast-500: map-get($secondary-palette, contrast, 500);
$secondary-contrast-500: $secondary-contrast-500;


/*tertiary*/
$tertiary-palette: (
  50:  tint($tertiary-color, 10 * map-get($lights, 50)),
  100: tint($tertiary-color, 10 * map-get($lights, 100)),
  200: tint($tertiary-color, 10 * map-get($lights, 200)),
  300: tint($tertiary-color, 10 * map-get($lights, 300)),
  400: tint($tertiary-color, 10 * map-get($lights, 400)),
  500: $tertiary-color,
  600: shade($tertiary-color, 10 * map-get($darks, 600)),
  700: shade($tertiary-color, 10 * map-get($darks, 700)),
  800: shade($tertiary-color, 10 * map-get($darks, 800)),
  900: shade($tertiary-color, 10 * map-get($darks, 900)),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$tertiary-50: map-get($tertiary-palette, 50);
$tertiary-100: map-get($tertiary-palette, 100);
$tertiary-200: map-get($tertiary-palette, 200);
$tertiary-300: map-get($tertiary-palette, 300);
$tertiary-400: map-get($tertiary-palette, 400);
$tertiary-500: $tertiary-color;
$tertiary-600: map-get($tertiary-palette, 600);
$tertiary-700: map-get($tertiary-palette, 700);
$tertiary-800: map-get($tertiary-palette, 800);
$tertiary-900: map-get($tertiary-palette, 900);

$tertiary-contrast-500: map-get($tertiary-palette, contrast, 500);
$tertiary-contrast-500: $tertiary-contrast-500;


/*danger*/
$danger-palette: (
  50:  tint($danger-color, 10 * map-get($lights, 50)),
  100: tint($danger-color, 10 * map-get($lights, 100)),
  200: tint($danger-color, 10 * map-get($lights, 200)),
  300: tint($danger-color, 10 * map-get($lights, 300)),
  400: tint($danger-color, 10 * map-get($lights, 400)),
  500: $danger-color,
  600: shade($danger-color, 10 * map-get($darks, 600)),
  700: shade($danger-color, 10 * map-get($darks, 700)),
  800: shade($danger-color, 10 * map-get($darks, 800)),
  900: shade($danger-color, 10 * map-get($darks, 900)),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$danger-50: map-get($danger-palette, 50);
$danger-100: map-get($danger-palette, 100);
$danger-200: map-get($danger-palette, 200);
$danger-300: map-get($danger-palette, 300);
$danger-400: map-get($danger-palette, 400);
$danger-500: $danger-color;
$danger-600: map-get($danger-palette, 600);
$danger-700: map-get($danger-palette, 700);
$danger-800: map-get($danger-palette, 800);
$danger-900: map-get($danger-palette, 900);

$danger-contrast-500: map-get($danger-palette, contrast, 500);

/*success*/
$success-palette: (
  50:  tint($success-color, 10 * map-get($lights, 50)),
  100: tint($success-color, 10 * map-get($lights, 100)),
  200: tint($success-color, 10 * map-get($lights, 200)),
  300: tint($success-color, 10 * map-get($lights, 300)),
  400: tint($success-color, 10 * map-get($lights, 400)),
  500: $success-color,
  600: shade($success-color, 10 * map-get($darks, 600)),
  700: shade($success-color, 10 * map-get($darks, 700)),
  800: shade($success-color, 10 * map-get($darks, 800)),
  900: shade($success-color, 10 * map-get($darks, 900)),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$success-50: map-get($success-palette, 50);
$success-100: map-get($success-palette, 100);
$success-200: map-get($success-palette, 200);
$success-300: map-get($success-palette, 300);
$success-400: map-get($success-palette, 400);
$success-500: $success-color;
$success-600: map-get($success-palette, 600);
$success-700: map-get($success-palette, 700);
$success-800: map-get($success-palette, 800);
$success-900: map-get($success-palette, 900);

$success-contrast-500: map-get($success-palette, contrast, 500);

/*info*/
$info-palette: (
  50:  tint($info-color, 10 * map-get($lights, 50)),
  100: tint($info-color, 10 * map-get($lights, 100)),
  200: tint($info-color, 10 * map-get($lights, 200)),
  300: tint($info-color, 10 * map-get($lights, 300)),
  400: tint($info-color, 10 * map-get($lights, 400)),
  500: $info-color,
  600: shade($info-color, 10 * map-get($darks, 600)),
  700: shade($info-color, 10 * map-get($darks, 700)),
  800: shade($info-color, 10 * map-get($darks, 800)),
  900: shade($info-color, 10 * map-get($darks, 900)),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$info-50: map-get($info-palette, 50);
$info-100: map-get($info-palette, 100);
$info-200: map-get($info-palette, 200);
$info-300: map-get($info-palette, 300);
$info-400: map-get($info-palette, 400);
$info-500: $info-color;
$info-600: map-get($info-palette, 600);
$info-700: map-get($info-palette, 700);
$info-800: map-get($info-palette, 800);
$info-900: map-get($info-palette, 900);

$info-contrast-500: map-get($info-palette, contrast, 500);

/*warning*/
$warning-palette: (
  50:  tint($warning-color, 10 * map-get($lights, 50)),
  100: tint($warning-color, 10 * map-get($lights, 100)),
  200: tint($warning-color, 10 * map-get($lights, 200)),
  300: tint($warning-color, 10 * map-get($lights, 300)),
  400: tint($warning-color, 10 * map-get($lights, 400)),
  500: $warning-color,
  600: shade($warning-color, 10 * map-get($darks, 600)),
  700: shade($warning-color, 10 * map-get($darks, 700)),
  800: shade($warning-color, 10 * map-get($darks, 800)),
  900: shade($warning-color, 10 * map-get($darks, 900)),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$warning-50: map-get($warning-palette, 50);
$warning-100: map-get($warning-palette, 100);
$warning-200: map-get($warning-palette, 200);
$warning-300: map-get($warning-palette, 300);
$warning-400: map-get($warning-palette, 400);
$warning-500: $warning-color;
$warning-600: map-get($warning-palette, 600);
$warning-700: map-get($warning-palette, 700);
$warning-800: map-get($warning-palette, 800);
$warning-900: map-get($warning-palette, 900);

$warning-contrast-500: map-get($warning-palette, contrast, 500);


$bg-color: $pure-white;
$font-color: $gray-900;
$input-border-color: $gray-100;
$form-disable-bg: #E4E4E4;

/*misc*/
$orange: #FF9500;
$pink: #F44C75;

:root{
  --bs-primary-bg-subtle: #{$primary-100};
}
