.badge {
  border-radius: 20px;
  font-weight: 600 !important;

  &.bg-secondary {
    color: $secondary-600;
    border: 1px solid $secondary-500;
    background: $secondary-100 !important;
  }

  &.bg-success {
    color: $success-800;
    border: 1px solid $success-800;
    background: $success-50 !important;
  }

  &.bg-warning {
    color: $warning-800;
    border: 1px solid $warning-800;
    background: $warning-50 !important;
  }

  &.bg-danger {
    color: $danger-800;
    border: 1px solid $danger-800;
    background: $danger-50 !important;
  }

  &.bg-info {
    color: $info-800;
    border: 1px solid $info-800;
    background: $info-50 !important;
  }
}